import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import Table from 'components/Table';

// import { IGroup } from 'context/backendData/interfaces';

import useConfirm from 'hooks/useConfirm';
import useUserTableSettings from 'hooks/useUserTableSettings';

import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useDeleteSubscriber from 'hooks/services/simManagement/subscriber/useDeleteSubscriber';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import { Sim } from 'hooks/services/simManagement/interfaces';
import SubscriberModal from '../SubscriberModal';

const hiddenColumns = [
  'assigned_to_probe_alias',
  'tariff',
  'wnw',
  'op_wnw',
  'psp',
  'wholesale_id',
  'itg_id',
  'secret',
  'name',
  'origin',
  'prepaid',
  'type',
  'modified',
  'position'
];

type ShowSubscriberState = null | { data: null | number };

const FixedNumbersTable = (props: any) => {
  const { tableColumns, setMessage, setErrMessage } = props;
  const { data: simCards, refetch: getSimCards } = useGetSubscribers();
  const { data: probes } = useGetProbes();
  const [selectedSims, setSelectedSims] = useState<any>();

  const [showSubscriberModal, setShowSubscriberModal] = useState<ShowSubscriberState>(null);

  const userTableSettings = useUserTableSettings('fixed-numbers', { hiddenColumns });

  const [deleteSim] = useDeleteSubscriber(
    {
      onSuccess: () => {
        setMessage('SIMs have been deleted');
        getSimCards();
      },
      onError: () => setErrMessage('unable to delete SIM(s)'),
    },
  );
  // todo implement hook when API provides the endpoint
  const deleteSelectedSims = () => {
    selectedSims.map((sim: any) => deleteSim({ id: sim }));
  };

  // add confirm modal
  const confirm = useConfirm();
  // delete selected sims
  const handleDelete = async (e: any) => {
    e.preventDefault();
    confirm({
      body: 'Do you really want to delete selected SIMs?',
      onOk: () => deleteSelectedSims(),
      title: 'Delete SIMs',
    });
  };

  const handleSelectedRowsChange = useCallback((rows: any) => {
    setSelectedSims((prevState: any) => {
      const newSelectedSims = _.map(rows, 'id');

      if (!_.isEqual(prevState, newSelectedSims)) {
        return newSelectedSims;
      }

      return prevState;
    });
  }, []);

  const openModal = (value: any) => {
    console.log('group data: ', value);
    setShowSubscriberModal({ data: value?.id });
  };

  const tableRowActions = useMemo(() => ({
    content: 'Actions',
    items: [
      {
        content: 'Edit',
        key: 'edit',
        onClick: (row: any) => openModal(row),
        variant: 'outline-primary'
      },
    ],
  }), []);

  const tableData = simCards
    ?.filter((simCard: Sim) => !simCard.position && simCard.type === 'fixed_line')
    .map((sim: Sim) => {
      const assignedProbe = probes?.find(
        (probe: any) => probe.probe_name === sim?.assigned_to?.probe_name
      );

      return {
        ...sim,
        assigned_to_name_alias: `${assignedProbe?.probe_alias || ''}_&_${assignedProbe?.probe_name || ''}`,
        network: [sim?.lab && 'lab', sim?.live && 'live'],
      };
    });

  const modifiedTableColumns = tableColumns.map((column: any) => (column.accessor === 'msisdn'
    ? { ...column, Header: 'Number' }
    : column));

  modifiedTableColumns.splice(1, 0, {
    Header: 'Assigned to',
    accessor: 'assigned_to_name_alias',
    isSortable: true,
    Cell: ({ value }: any) => <>{value.split('_&_')[0] || ''}</>,
  });
  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        actionsPosition="left"
        columns={modifiedTableColumns}
        data={tableData ?? []}
        DeleteButtonProps={{
          id: 'delete-sim-cards',
          content: 'Delete',
          disabled: _.toSafeInteger(selectedSims?.length) < 1,
          onClick: handleDelete,
        }}
        initialState={userTableSettings?.initialState}
        NewButtonProps={{
          id: 'add-subscriber',
          content: 'Add',
          onClick: () => setShowSubscriberModal({ data: null }),
        }}
        onSelectedRowsChange={handleSelectedRowsChange}
        onStateChange={userTableSettings.updateTableSettings}
        rowActions={tableRowActions}
        selectable
        showSettingsMenu
        skipReset={false}
        stickyActions
        stickyHeader
      />
      )}
      {showSubscriberModal
        && (
          <SubscriberModal
            onHide={() => setShowSubscriberModal(null)}
            show
            subscriberId={showSubscriberModal?.data}
            type="fixed_line"
            // getSimCards={getSimCards}
          />
        )}
    </>
  );
};

export default FixedNumbersTable;
