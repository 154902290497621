import _ from 'lodash';

import { useAuth } from 'react-oidc-context';
import { AxiosRequestConfig } from 'axios';

import useAxios from './useAxios';

export default function useGetAxiosWithToken(options?: AxiosRequestConfig) {
  const auth = useAuth();

  const axiosInstance = useAxios(options);

  axiosInstance.interceptors.request.use((config) => {
    const configWithAuthorizationToken = _.cloneDeep(config);

    configWithAuthorizationToken.headers.Authorization = `Bearer ${auth.user?.access_token}`;

    return configWithAuthorizationToken;
  });

  return axiosInstance;
}
