import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeviceType = {
  desired_mapping?: {
    [iccid: string]: string
  };
  probe_alias: string;
};
export type ScheduleSleepJobType = {
  reservation_duration: number;
  user_id: string;
  filters: DeviceType[];
};

export default function useScheduleSleepJob(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation((sleepJob : ScheduleSleepJobType) => axios.post(
    'scheduler/api/schedule/sleep_job',
    sleepJob,
  ).then(({ data }) => data), options);
}
