import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { RawUserProfile, UserProfile } from 'types/auth/userProfile';
import { extractRoles } from 'utils/roleHelper';

const useUser = (): UserProfile => {
  const auth = useAuth();

  const userProfile: UserProfile = useMemo(() => {
    if (auth.isAuthenticated && auth.user) {
      const tokenData = auth.user.profile as RawUserProfile;
      const { roles, isAdmin } = extractRoles(tokenData.groups);

      return {
        ...tokenData,
        roles,
        isAdmin,
        userId: tokenData.uidNumber,
        token: auth.user.access_token,
      };
    }

    return {
      isAdmin: false,
      roles: [],
    };
  }, [auth.isAuthenticated, auth.user]);

  return userProfile;
};

export default useUser;
