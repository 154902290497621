import React from 'react';

import { useAuth } from 'react-oidc-context';

import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
} from 'react-bootstrap';

import useModal from 'hooks/useModal';

import ManualTesting from 'pages/Help/Documentation/ManualTesting';

export default function HomePage() {
  const auth = useAuth();

  const demoVideoModal = useModal();

  const handleLogin = async () => {
    try {
      await auth.signinRedirect();
    } catch (error) {
      console.error('Error during sign-in redirect:', error);
    }
  };

  return (
    <div>
      {/* ############## Carousel on top of the page ################## */}
      <section className="mb-5">
        <div className="hero dimmed">
          <div className="hero-image" style={{ backgroundImage: 'url(/img/338868_unlimited_master_20170511RZbiere_018_M.jpg)' }} />
          <div className="hero-content">
            <h1 className="display-4 font-weight-bold mb-0 text-uppercase">
              Welcome to TESSA
            </h1>
            <Container className="bu-mt-3">
              <Row>
                <Col md="2" />
                <Col>
                  <h3 className="bu-mb-2">
                    We support our customers to easily run End2End manual &amp;
                    automated tests in the area of Voice Calls, Data transfer, Wifi,
                    Tariff checks, VoLTE/Roaming and many more.
                  </h3>
                  {
                  !auth.user?.profile && !auth.isAuthenticated
                  && (
                  <Button
                    onClick={handleLogin}
                    size="lg"
                  >Login
                  </Button>
                  )
}
                </Col>
                <Col md="2" />
              </Row>
            </Container>
          </div>
        </div>
      </section>
      {/* ############## Main section - introduction + Welcome ################## */}
      <section className="margin-bottom margin-top">
        <Container>
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 5, offset: 1 }}>
              <Card className="text-center" style={{ height: '100%' }}>
                <Card.Body className="py-5" style={{ background: '#e20074', color: 'white', display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
                  <Card.Title style={{ fontSize: '25px', marginBottom: '12px' }}>MANUAL REMOTE TESTING</Card.Title>
                  <div>
                    <Button onClick={() => demoVideoModal.showModal()} variant="light">Watch Demo Video</Button>
                  </div>
                  <Card.Text className="mt-4">
                    You need to execute End2End tests on real devices in several locations,
                    without spending too much travel costs?
                  </Card.Text>
                </Card.Body>
                <Card.Footer style={{ minHeight: '100px', backgroundColor: 'white' }}>
                  With our End2End manual remote test solution you can easily execute
                  tests on real devices in several location all over the world.
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={12} md={5}>
              <Card className="text-center" style={{ height: '100%' }}>
                <Card.Body className="py-5" style={{ background: '#e20074', color: 'white' }}>
                  <Card.Title style={{ fontSize: '25px' }}>AUTOMATED TESTING</Card.Title>
                  <Card.Text className="mt-4">
                    You need to run End2End regression tests in Lab or
                    Production environment on real devices?
                  </Card.Text>
                </Card.Body>
                <Card.Footer style={{ minHeight: '100px', backgroundColor: 'white' }}>
                  With our Automated Testing you can plan, define and execute
                  automated End2End test on several devices like Mobile phones,
                  IP-Phones and modems enriched with a detailed test reporting.
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal size="lg" show={demoVideoModal?.show} onHide={() => demoVideoModal.hideModal()} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Manual Testing Demo Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ManualTesting />
        </Modal.Body>
      </Modal>
    </div>
  );
}
