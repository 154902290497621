import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired, useAuth } from 'react-oidc-context';

import useUser from 'hooks/useUser';

import {
  Col, Container, Row,
} from 'react-bootstrap';
import Login from 'pages/Login';

const InitializingSSO = () => (
  <Container as="main">
    <Row>
      <Col md={{ span: 6, offset: 3 }} xs={{ span: 12, offset: 0 }}>
        <div className="bu-mt-5">
          <h1 className="font-weight-bold h4 text-center">TESSA</h1>
        </div>
        <div className="bu-mb-4 bu-mt-4"><h2 className="display-4 text-center">Initializing SSO ...</h2></div>
      </Col>
      <Col />
    </Row>
  </Container>
);

type PrivateRouteProps = {
  roles?: string[];
  users?: string[];
  path: string;
  component?: React.ComponentType<any>;
};

const PrivateRouteComponent = ({
  roles = [],
  users = [],
  ...routeProps
}: PrivateRouteProps) => {
  const auth = useAuth();
  const { roles: currentUserRoles, preferred_username: currentUser } = useUser();

  if (!currentUser) {
    return null;
  }

  if (window.top !== window.self) {
    if (!auth.isAuthenticated) {
      return <Login />;
    }
  }

  const isRoleRestricted = roles.length > 0;
  const userHasRole = roles.some((r) => currentUserRoles.includes(r));
  const isUserRestricted = users.length > 0;
  const isAllowedUser = users.includes(currentUser);

  if ((isRoleRestricted && !userHasRole) || (isUserRestricted && !isAllowedUser)) {
    return null;
  }

  return <Route {...routeProps} />;
};

export default withAuthenticationRequired(PrivateRouteComponent, {
  OnRedirecting: () => <InitializingSSO />,
  signinRedirectArgs: {
    redirect_uri: window.location.origin + window.location.pathname,
  },
});
