import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

export const AutoLogout = () => {
  const auth = useAuth();

  useEffect(() => {
    const handleTokenExpiration = () => {
      auth.signoutRedirect();
    };

    auth.events.addAccessTokenExpired(handleTokenExpiration);

    return () => {
      auth.events.removeAccessTokenExpired(() => {});
    };
  }, [useAuth]);

  return null;
};

export default AutoLogout;
