import { useAuth } from 'react-oidc-context';
import React, { useEffect, useState } from 'react';

import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import useUser from 'hooks/useUser';

import useSendMail from 'hooks/services/mail/useSendMail';

const AccountRequestForm = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    description: '',
    to: 'account@its-telekom.eu'
  });
  const [selectedData, setSelectedData] = useState<any>([]);
  const [perfgoGroup, setPerfgoGroup] = useState('');
  const [otherRequest, setOtherRequest] = useState('');
  const [formerrors, setFormErrors] = useState<any>({});

  const auth = useAuth();
  const isUserLoggedIn = !!auth.user?.profile;

  const { given_name: givenName, family_name: familyName, email } = useUser();

  const [sendMail, { isLoading, error }] = useSendMail(
    {
      onSuccess: (data) => {
        console.log(data);
        if (data && !data.error && data.success) {
          setShowMessage(true);
          // reset form
          setPerfgoGroup('');
          setOtherRequest('');
          setSelectedData([]);
          setValues((vals) => ({
            ...vals,
            description: '',
          }));
        } else {
          console.log(`sending mail error: ${data.error}`);
        }
      },
    }
  );

  const checkData = [
    { name: 'Jenkins/Automated Testing Group',
      sub: [
        'Customer Service',
        'HLR/HSS',
        'HTVAT',
        'HUVAT',
        'GRVAT',
        'SKVAT',
        'CZVAT',
        'IVA internal',
        'TMSP',
        'DAT',
        'TDG-SDM-Ref',
        'TDI-SDM-Test',
      ]
    },
    { name: 'Manual Testing Group',
      sub: [
        'HLR',
        'Avengers',
        'HTVAT production',
        'HTVAT reference',
        'IVA',
        'TDG-SDM-Ref',
        'TDI-SDM-Test',
        'HTVAT',
        'HUVAT',
        'GRVAT',
        'SKVAT',
        'CZVAT',
        'GTAF',
        'cLAB',
      ]
    },
    'Expert testing',
    'Grafana',
    'GitLab',
    'Icescrum',
    'Reportportal',
    { name: 'VPN',
      sub: [
        'WWTC',
        'WWTC Dev'
      ]
    },
    'FSA-Tool',
    { name: 'PerfGo',
      sub: ['Perfgo Group']
    },
    'Meshcentral',
    'Jitsi',
    { name: 'Other',
      sub: ['Other request']
    },
  ];

  const handleChangeCheckbox = (name: any) => {
    if (selectedData.includes(name)) {
      setSelectedData(selectedData?.filter((val:string) => val !== name));
    } else {
      setSelectedData([...selectedData || [], name]);
    }
  };

  // init form data
  useEffect(() => {
    setValues((vals) => ({
      ...vals,
      firstName: givenName || '',
      lastName: familyName || '',
      email: email || '',
    }));
  }, [givenName]);

  // this method handles the each form field changing and updates the relevant
  // state value for that input
  const handleChange = (event: any) => {
    console.log(
      `handleChange -> ${event.target.name} : ${event.target.value}`
    );
    setValues((vals) => ({
      ...vals,
      [event.target.name]: event.target.value,
    }));
  };

  // this method will check each field in your form. You define
  // the rules for each field
  const validate = () => {
    console.log('Validate the form....');
    const errors: any = {};
    // name field
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    // name field
    if (!values.lastName) {
      errors.lastName = 'Last name is required';
    }
    // email field
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
    if (!values.description) {
      errors.description = 'List of users is required';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    }
    return false;
  };

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    if (validate()) {
      const mailBody = `Users:\n${values.description}\nServices: [${selectedData?.sort()}]\nPerfgo Group: [${perfgoGroup}]\nOther request: [${otherRequest}]`;
      const mailHtml = `<div>
        <h2>Users</h2>
        <div><ul>${values.description.split('\n').filter((e:any) => e !== '').map((user: any) => `<li>${user}</li>`).join('')}</ul></div>
        <h2>Services</h2>
        <div><ul>${selectedData.sort().map((item: any) => `<li>${item}</li>`).join('')}</ul></div>
        ${perfgoGroup ? '<h2>Perfgo Group</h2>' : ''}
        <div>${perfgoGroup}</div>
        ${otherRequest ? '<h2>Other Request</h2>' : ''}
        <div>${otherRequest}</div>
      </div>`;
      sendMail({ mail: { from: values.email, to: values.to, subject: `TESSA UI - Account Request From ${values.firstName} ${values.lastName}`, text: mailBody, html: mailHtml } });
    } else {
      setShowMessage(false);
    }
  };

  return (
    <>
      <Alert show={showMessage} variant="success">
        <Alert.Heading>Success</Alert.Heading>
        <hr />
        <p>
          Thank you for reaching out to us!
        </p>
        <p style={{ color: 'green' }}>
          Your account request was succesfully send and we are working on it.
        </p>
        <p>
          We&apos;ll come back to you as soon as possible.
        </p>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              setShowMessage(false);
            }}
            variant="outline-success"
          >
            Ok
          </Button>
        </div>
      </Alert>
      <Alert show={Boolean(error)} variant="danger">
        <Alert.Heading>Sending error</Alert.Heading>
        <hr />
        <p>
          {/* {error?.message} */}
          {JSON.stringify(error?.message)}
        </p>
      </Alert>
      <Alert show={isLoading} variant="info">
        <Alert.Heading>Sending your request...</Alert.Heading>
      </Alert>
      {(isLoading) && (
        <div className="spinner-container">
          <Spinner animation="border" className="spinner" role="status" />
        </div>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              placeholder="Enter your first name"
              type="text"
              name="firstName"
              value={values.lastName}
              disabled={isUserLoggedIn}
              onChange={handleChange}
            />
            {formerrors.firstName && (
              <p className="text-warning">{formerrors.firstName}</p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Enter your last name"
              type="text"
              name="lastName"
              value={values.firstName}
              disabled={isUserLoggedIn}
              onChange={handleChange}
            />
            {formerrors.lastName && (
              <p className="text-warning">{formerrors.lastName}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              placeholder="Enter email"
              type="email"
              name="email"
              value={values.email}
              disabled={isUserLoggedIn}
              onChange={handleChange}
            />
            {formerrors.email && (
              <p className="text-warning">{formerrors.email}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridDescription">
            <Form.Label>
              New users - one per line (first name, surname, mail adress)(required)
            </Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              name="description"
              value={values.description}
              onChange={handleChange}
              rows={6}
            />
            {formerrors.description && (
              <p className="text-warning">{formerrors.description}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group>
            {checkData.map(
              (value) => {
                if (typeof value === 'object') {
                  return (
                    <span key={`${value.name}-span`}>
                      <div key={value.name} className="d-inline">
                        <Form.Check
                          checked={selectedData?.includes(value.name)}
                          value={value.name}
                          id={value.name}
                          key={value.name}
                          label={value.name}
                          aria-label={value.name}
                          className="lg mr-0 ml-3"
                          onChange={() => handleChangeCheckbox(value.name)}
                        />
                      </div>
                      {selectedData.includes(value.name) && value?.sub?.map(
                        (val:any) => {
                          if (val === 'Perfgo Group') {
                            return (
                              <div key={val} style={{ marginLeft: '10px' }}>
                                <Form.Group as={Col} controlId="perfgoGroup">
                                  <Form.Label>{val}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="perfgoGroup"
                                    value={perfgoGroup}
                                    onChange={(e: any) => setPerfgoGroup(e.target.value)}
                                  />
                                </Form.Group>
                              </div>
                            );
                          }
                          if (val === 'Other request') {
                            return (
                              <div key={val} style={{ marginLeft: '10px' }}>
                                <Form.Group as={Col} controlId="otherRequest">
                                  <Form.Label>{val}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="otherRequest"
                                    value={otherRequest}
                                    onChange={(e: any) => setOtherRequest(e.target.value)}
                                  />
                                </Form.Group>
                              </div>
                            );
                          }
                          return (
                            <div key={val} style={{ marginLeft: '10px' }}>
                              <Form.Check
                                checked={selectedData?.includes(`${value.name}-${val}`)}
                                value={val}
                                id={val}
                                key={val}
                                label={val}
                                aria-label={val}
                                className="lg mr-0 ml-3"
                                onChange={() => handleChangeCheckbox(`${value.name}-${val}`)}
                              />
                            </div>
                          );
                        }
                      )}
                    </span>
                  );
                }
                return (
                  <div key={value} className="d-inline">
                    <Form.Check
                      checked={selectedData?.includes(value)}
                      value={value}
                      id={value}
                      key={value}
                      label={value}
                      aria-label={value}
                      className="lg mr-0 ml-3"
                      onChange={() => handleChangeCheckbox(value)}
                    />
                  </div>
                );
              }
            )}
          </Form.Group>
        </Form.Row>
        <Row>
          <Col />
          <Col md={2}>
            <Button
              type="submit"
              variant="primary"
              disabled={showMessage}
            >
              Send
            </Button>
          </Col>
          <Col />
        </Row>
      </Form>

    </>
  );
};

export default AccountRequestForm;
