import { useAuth } from 'react-oidc-context';
import { Redirect } from 'react-router-dom';

export const LoginRedirect = () => {
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    auth.signinRedirect();
  }

  if (auth.isAuthenticated) {
    return <Redirect to="/login-redirect-close" />;
  }

  return null;
};

export default LoginRedirect;
