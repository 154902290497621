import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig = {
  authority: `${process.env.REACT_APP_KEYCLOAK}/realms/wwtc`,
  client_id: ['tessa.its', 'tessa.its-telekom.eu', 'tessa.automation.telekom.net'].includes(window.location.hostname) ? 'tessaprod' : 'tessadev',
  automaticSilentRenew: false,
  monitorSession: true,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  post_logout_redirect_uri: window.location.origin,
  querySessionStatus: console.log,
  redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export default oidcConfig;
