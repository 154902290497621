/**
 * Extracts roles from groups based on a regex pattern.
 * @param groups - Array of group strings from the user profile.
 * @param roleRegex - Regex to match and extract roles.
 * @returns An object containing extracted roles and an isAdmin flag.
 */
export const extractRoles = (
  groups: string[] | undefined,
  roleRegex: RegExp = /tessa[\w-]+-roles-([\w-]+)/,
): { roles: string[]; isAdmin: boolean } => {
  const roles: string[] = [];
  let isAdmin = false;

  if (groups) {
    groups.forEach((group) => {
      const roleMatch = group.match(roleRegex);
      if (roleMatch?.[1]) {
        const role = roleMatch[1];
        roles.push(role);
        if (role.toLowerCase() === 'admin') {
          isAdmin = true;
        }
      }
    });
  }

  return { roles, isAdmin };
};

export default extractRoles;
